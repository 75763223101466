import {Injectable} from '@angular/core';
import {BaseApi} from '../base-api';

@Injectable({
    providedIn: 'root'
})
export class ReportService extends BaseApi {

    getReportsByPage = (page: number = 1, generated?: boolean): Promise<any> => {
        let url = `/report/ByPage?pageIndex=${page}`;

        if (generated != null) {
            url += `&generated=${generated}`;
        }
        return this.get(url);
    }

    getReport = (id: string): Promise<any> => this.get(`/report/${id}`);

    addReport = (report: object): Promise<any> => this.post('/report', report);

    editReport = (report: object): Promise<any> => this.put('/report', report);

    executeFilter = (filterData: object): Promise<any> => this.post('/report/ExecuteFilter', filterData);

    deleteReport = (id: number): Promise<any> => this.delete(`/report/${id}`);

    getReportControl = (id: number): Promise<any> => this.get(`/reportControl/${id}`);

    getReportControls = (id: string): Promise<any> => this.get(`/reportControl/byReport?id=${id}`);

    addReportControl = (control: object): Promise<any> => this.post('/reportControl/', control);

    deleteReportControl = (id: number): Promise<any> => this.delete(`/reportControl/${id}`);

    editReportControl = (report: object): Promise<any> => this.put('/reportControl', report);
}
