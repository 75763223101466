import {Injectable} from '@angular/core';
import {BaseApi} from '../base-api';

@Injectable({
    providedIn: 'root'
})
export class ProhibitionService extends BaseApi {

    getById = (id: number): Promise<any> => this.get(`/Prohibition/${id}`);
    getList = (): Promise<any> => this.get('/Prohibition');
    prohibitionAdd = (data: object): Promise<any> => this.post('/Prohibition', data);
    prohibitionEdit = (data: object): Promise<any> => this.put('/Prohibition', data);
    prohibitionDelete = (id: string): Promise<any> => this.delete(`/Prohibition/${id}`);

    getByMedicine = (medicineId: number): Promise<any> => this.get(`/Medicine/${medicineId}/prohibitions`);
    addToMedicine = (data: object): Promise<any> => this.post('/Medicine/AddProhibition', data);
    deleteFromMedicine = (data: object): Promise<any> => this.post('/Medicine/RemoveProhibition', data);

    getByDisease = (diseaseId: number): Promise<any> => this.get(`/Disease/${diseaseId}/prohibitions`);
    addToDisease = (data: object): Promise<any> => this.post('/Disease/AddProhibition', data);
    deleteFromDisease = (data: object): Promise<any> => this.post('/Disease/RemoveProhibition', data);

    getByAnimal = (animalId: string): Promise<any> => this.get(`/Prohibition/animal/${animalId}`);
}
