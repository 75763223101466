import { Injectable } from '@angular/core';
import {BaseApi} from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class EartagService extends BaseApi {
    getHistory = (tag: string, speciesId: string): Promise<any> => this.get(`/EarTag/${tag}/History?speciesId=${speciesId}`);
    getStockByPage = (filterData: object): Promise<any> => this.post('/EarTag/GetStockByPage', filterData);
    updateStatus = (tagData: object): Promise<any> => this.post('/EarTag/UpdateEarTagStatus', tagData);

    getLocalOrdersByPage = (filterData: object): Promise<any> => this.post('/EarTag/GetLocalOrdersByPage', filterData);
    getCentralOrdersByPage = (filterData: object): Promise<any> => this.post('/EarTag/GetCentralOrdersByPage', filterData);

    getRegistrars = (): Promise<any> => this.get('/EarTag/ListRegistrars?');
    getModelsForSpecies = (speciesId: string): Promise<any> => this.get(`/EarTag/ModelsForSpecies?speciesId=${speciesId}`);

    getLocalOrderHistory = (orderId: string): Promise<any> => this.get(`/EarTag/${orderId}/LocalOrderHistory`);
    getCentralOrderHistory = (orderId: string): Promise<any> => this.get(`/EarTag/${orderId}/CentralOrderHistory`);
    getLocalOrderDetails = (orderId: string): Promise<any> => this.get(`/EarTag/byLocalOrder/${orderId}`);
    getStockByRegistrar = (registrarId: string, modelId: string) => this.get(`/EarTag/StockByRegistrar/${registrarId}/${modelId}`);

    getAvailableFirstTagNumber = (speciesId: string): Promise<any> => this.post(`/EarTag/CentralOrder/GetAvailableFirstTagNumber/${speciesId}`);

    addLocalOrder = (orderData: object): Promise<any> => this.post('/EarTag/NewLocalOrder', orderData);
    deleteLocalOrder = (orderId: number): Promise<any> => this.delete(`/EarTag/LocalOrder/${orderId}`);
    addCentralOrder = (orderData: object): Promise<any> => this.post('/EarTag/NewCentralOrder', orderData);

    setAsDelivered = (orderData: object): Promise<any> => this.post('/EarTag/LocalOrders/SetAsDelivered', orderData);
    transfer = (data: object): Promise<any> => this.post('/EarTag/TransferEarTagSeries', data);
    deleteNotAllLocalOrder = (orderId: number): Promise<any> => this.get(`/EarTag/DeleteNotAllByLocalOrderId/${orderId}`);
    deleteAllLocalOrder = (orderId: number): Promise<any> => this.get(`/EarTag/DeleteByLocalOrderId/${orderId}`);

}
