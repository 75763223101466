import {Injectable} from '@angular/core';
import {BaseApi} from '../base-api';

@Injectable({
    providedIn: 'root'
})
export class TaskService extends BaseApi {
    objectToParams(params: object): string {
        let url = '';

        Object.keys(params).forEach(param => {
            if (params[param] !== null) {

                if (Array.isArray(params[param])) {
                    params[param].forEach(p => {
                        url += `&${param}=${encodeURIComponent(p)}`;
                    });
                } else {
                    url += `&${param}=${encodeURIComponent(params[param])}`;
                }
            }
        });

        return url;
    }

    getTasksByParams = (params: object): Promise<any> => {
        let url = '/Task/ByParams?';
        const paramsStr = this.objectToParams(params);

        url += paramsStr;

        return this.get(url);
    };

    getTasksByUserParams = (params: object): Promise<any> => {
        let url = '/Task/ByUserParams?';
        const paramsStr = this.objectToParams(params);

        url += paramsStr;

        return this.get(url);
    };

    reAssign = (filterData: object): Promise<any> => this.post('/Task/ReAssignByParams', filterData);

    rejectByParams = (filterData: object): Promise<any> => this.post('/Task/RejectByParams', filterData);

    reject = (rejectData: object): Promise<any> => this.post('/Task/RejectTask', rejectData);

    setStatus = (filterData: object): Promise<any> => this.post('/Task/SetStatusByParams', filterData);
}
