import {Injectable} from '@angular/core';
import {BaseApi} from '../base-api';
import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';

@Injectable({
    providedIn: 'root'
})
export class LaboratoryService extends BaseApi {

     getLaboratory = (id: number): Promise<any> => this.get(`/laboratory/${id}`);

    getAllLaboratories = (): Promise<any> => this.get('/laboratory');

    getHealthUsersByRegions(regionId) { 
        const url = '/user/ByRegionIdsAndProfileIds?RegionIds=' + regionId;
        return this.get(url);
    }
    getUserByProfileIdsAndRegionIds = (searchParams: object): Promise<any> => this.post('/User/ByRegionIdsAndProfileIds', searchParams);

    addLaboratory = (laboratory: object): Promise<any> => this.post('/laboratory/add', laboratory);

    editLaboratory = (laboratory: object): Promise<any> => this.put('/laboratory', laboratory);

    deleteLaboratory = (id: number): Promise<any> => this.delete(`/laboratory/delete?id=${id}`);

    getLaboratoryChecklists = (id: number): Promise<any> => this.get(`/MaterialSampling/AnalysisChecklistsByLaboratory?laboratoryId=${id}`);

    getMaterialsByChecklistId = (id: string): Promise<any> => this.get(`/MaterialSampling/AnalysisMaterialByChecklist?checklistId=${id}`);

    getEventsByMaterialId = (id: string): Promise<any> => this.get(`/MaterialSampling/AnalysisMaterialEventIds?analysisMaterialId=${id}`);

    addAnalysisRefuse = (refuse: object): Promise<any> => this.post('/MaterialSampling/RejectAnalysisMaterials', refuse);

    addAnalysisResult = (result: object): Promise<any> => this.post('/MaterialSampling/AddAnalysisResult', result);

    acceptAnalysisMaterial = (result: object): Promise<any> => this.post('/MaterialSampling/AcceptAnalysisMaterials', result);

    // tslint:disable-next-line: max-line-length
    searchResultByChecklistId = (id: string): Promise<any> => this.get(`/MaterialSampling/AnalysisResultListDtoByChecklistId?checklistId=${id}`);

    getUserLaboratories = (): Promise<any> => this.get('/Laboratory/LaboratoriesByUser');


    // tslint:disable-next-line: max-line-length
    getChecklistsByParams = (page: number, laboratoryId: number, checklistId: string, status: number, orderNumber: string, fromDate: any, toDate: any ): Promise<any> => {
        let url = '/MaterialSampling/AnalysisChecklistsByParamsAndPageWeb';
        url += '?PageIndex=' + page;
        if (laboratoryId > 0) {
            url += '&LaboratoryId=' + laboratoryId;
        }
        if (!status) { status = 0; }
        url += '&Status=' + status;
        if (checklistId) { url += '&Id=' + checklistId; }
        if (orderNumber) { url += '&OrderNumber=' + orderNumber; }
        if (fromDate) { url += '&FromDate=' + fromDate; }
        if (toDate) { url += '&ToDate=' + toDate; }
        return this.get(url);
    }


    getChecklistsAitsByParams = (page: number, laboratoryId: number, checklistId: string, status: number, perPage: number, fromDate: any, toDate: any ): Promise<any> => {
        let url = '/MaterialSampling/AnalysisChecklistsByParamsAndPageAits';
        url += '?PageIndex=' + page;
        if (laboratoryId > 0) {
            url += '&LaboratoryId=' + laboratoryId;
        }
        if (!status) { status = 0; }
        url += '&Status=' + status;
        if (checklistId) { url += '&Id=' + checklistId; }
        if (perPage) { url += '&PerPage=' + perPage; }
        if (fromDate) { url += '&FromDate=' + fromDate; }
        if (toDate) { url += '&ToDate=' + toDate; }       
        return this.get(url);
    }


    getAllResearchMethods = (): Promise<any> => this.get('/ResearchMethod');
    addResearchMethods = (researchMethod: object): Promise<any> => this.post('/ResearchMethod/add', researchMethod);
    editResearchMethods = (researchMethod: object): Promise<any> => this.put('/ResearchMethod', researchMethod);
    deleteResearchMethod = (id: number): Promise<any> => this.delete(`/ResearchMethod/${id}`);

     // tslint:disable-next-line: max-line-length
    getAllResearchMethodTypes = (methodId: number): Promise<any> => this.get(`/ResearchMethod/ResearchMethodTypesByMethodId?methodId=${methodId}`);
    // tslint:disable-next-line: max-line-length
    addResearchMethodType = (researchMethodType: object): Promise<any> => this.post('/ResearchMethod/AddResearchMethodType', researchMethodType);
    deleteResearchMethodType = (id: number): Promise<any> => this.delete(`/ResearchMethod/DeleteResearchMethodType?id=${id}`);

    addOrderNumber = (orderNumber: object): Promise<any> => this.post('/MaterialSampling/AddOrderNumberToChecklist', orderNumber);

    getChecklistById = (id: string): Promise<any> => this.get(`/MaterialSampling/${id}`);
    getAnalysissByChecklistId = (id: string): Promise<any> => this.get(`/MaterialSampling/AnalysissByChecklistId?checklistId=${id}`);
    // tslint:disable-next-line: max-line-length
    getDiseaseListByChecklistId = (id: string): Promise<any> => this.get(`/MaterialSampling/DiseaseAndEventIdsByChecklist?checklistId=${id}`);
    getMethodsByAnalysisTypeId = (id: number): Promise<any> => this.get(`/ResearchMethod/ResearchMethodsByAnalysisTypeId?typeId=${id}`);

    getMethodTypesByMethodId = (id: number): Promise<any> => this.get(`/ResearchMethod/ResearchMethodTypesByMethod?methodId=${id}`);
    getResultTypesByMethodId = (id: number): Promise<any> => this.get(`/ResearchMethod/AnalysisResultsByMethod?methodId=${id}`);

    // tslint:disable-next-line: max-line-length
    deleteResultByMaterialAndDisease = (materialId: string, diseaseId: number): Promise<any> => this.delete(`/MaterialSampling/DeleteAnalysisResultByDisease?materialId=${materialId}&diseaseId=${diseaseId}`);
}
